// material-ui
import { useTheme } from "@emotion/react";
import { CircularProgress, styled } from "@mui/material";

// styles
const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 9999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(000, 000, 000, 0.5)",
});

// ==============================|| LOADER ||============================== //

const Loader = () => {
  const theme = useTheme();
  return (
    <LoaderWrapper>
      <CircularProgress sx={{ color: theme.palette.custom.primary }} />
    </LoaderWrapper>
  );
};

export default Loader;
