import NavigationScroll from "layout/NavigationScroll";
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { RouterProvider } from "react-router-dom";
import router from "routes";
import ThemeCustomization from "themes";

function App() {
    useEffect(() => {
      const tagManagerArgs = {
        gtmId: 'G-Z4DNT6CX7R',
      };
      TagManager.initialize(tagManagerArgs);
    }, []);
  return (
    <ThemeCustomization>
      <NavigationScroll>
        <RouterProvider router={router} />
      </NavigationScroll>
    </ThemeCustomization>
  );
}

export default App;
