import { Grid, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-scroll';

const footerData = [
  {
    title: 'Overview',
    links: [
      { text: 'About us', url: 'about-us' },
      { text: 'FAQs', url: 'faqs' },
      { text: 'Join the team', url: 'contact-us' },
    ],
  },
];

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  cursor: 'pointer',
  width: '100%',
}));

const NavLink = ({ to, children, onClick }) => (
  <StyledLink
    to={to}
    smooth={true}
    duration={500}
    offset={-70}
    spy={true}
    activeClass="active"
    onClick={onClick}
  >
    <Typography
      variant="f16"
      fontWeight={500}
      color={'text.white60'}
      sx={{
        textDecoration: 'none',
        width: '100%',
        ':hover': { textDecoration: 'underline' },
      }}
    >
      {' '}
      {children}
    </Typography>
  </StyledLink>
);

const Overview = () => {
  return (
    <>
      {footerData.map((section, index) => (
        <Grid item xs={12} sm={3} md={2.4} key={index}>
          <Stack gap={3}>
            <Typography variant="f20" fontWeight={600} color={'common.white'}>
              {section.title}
            </Typography>

            <Stack gap={2.5}>
              {section.links.map((link, linkIndex) => (
                <NavLink to={link.url}>{link.text}</NavLink>
              ))}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </>
  );
};

export default Overview;
