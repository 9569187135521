import { Box, styled } from "@mui/material";

const SectionBoxStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1280px",
  padding: "0 16px",
  margin: "0 auto",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    padding: "0 24px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 16px",
  },
}));

const SectionBox = (props) => {
  return <SectionBoxStyled {...props} />;
};

export default SectionBox;
