import { createRoot } from "react-dom/client";

import App from "App";
import { ConfigProvider } from "contexts/ConfigContext";
import reportWebVitals from "reportWebVitals";
import * as serviceWorker from "serviceWorker";
import "./assets/style.css";

// ==============================|| REACT DOM RENDER ||============================== //

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ConfigProvider>
    <App />
  </ConfigProvider>
);

serviceWorker.unregister();
reportWebVitals();
